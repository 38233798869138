"use client";

import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
export const labelVariants = cva("leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70", {
  variants: {
    variant: {
      default: "text-ink",
      primary: "text-primary",
      muted: "text-muted-foreground"
    },
    font: {
      mono: "font-mono font-medium tracking-wider",
      sans: "font-sans font-normal tracking-wide"
    },
    size: {
      xs: "text-xs",
      sm: "text-sm",
      base: "text-base",
      lg: "text-lg"
    }
  },
  defaultVariants: {
    variant: "default",
    font: "sans",
    size: "base"
  }
});
const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>>(({
  className,
  variant,
  font,
  size,
  ...props
}, ref) => <LabelPrimitive.Root ref={ref} className={cn(labelVariants({
  variant,
  font,
  size
}), className)} {...props} />);
Label.displayName = LabelPrimitive.Root.displayName;
export { Label };