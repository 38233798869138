"use client";

import * as TogglePrimitive from "@radix-ui/react-toggle";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
const toggleVariants = cva("inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-paper transition-colors hover:bg-muted hover:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=on]:bg-accent data-[state=on]:text-accent-foreground", {
  variants: {
    variant: {
      default: "bg-transparent",
      outline: "border border-border bg-transparent hover:bg-accent hover:text-accent-foreground"
    },
    size: {
      xs: "h-6 px-1.5",
      sm: "h-7 px-2.5",
      md: "h-8 px-3",
      lg: "h-10 px-4 text-sm"
    }
  },
  defaultVariants: {
    variant: "default",
    size: "sm"
  }
});
const Toggle = React.forwardRef<React.ElementRef<typeof TogglePrimitive.Root>, React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof toggleVariants>>(({
  className,
  variant,
  size,
  ...props
}, ref) => <TogglePrimitive.Root ref={ref} className={cn(toggleVariants({
  variant,
  size,
  className
}))} {...props} />);
Toggle.displayName = TogglePrimitive.Root.displayName;
export { Toggle, toggleVariants };