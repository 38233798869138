import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
import type { IBaseButtonProps, IButtonPropsWithChildren, IButtonPropsWithoutChildren } from "./button";
import { Button } from "./button";
import type { notificationDotVariants } from "./notification-dot";
import NotificationDot from "./notification-dot";
const buttonNotificationDotVariants = cva("absolute", {
  variants: {
    size: {
      xs: "-right-px top-0 size-1.5",
      sm: "-right-px -top-px size-1.5",
      md: "-right-0.5 -top-0.5 size-2",
      lg: "-right-0.5 -top-0.5 size-2"
    }
  },
  defaultVariants: {
    size: "md"
  }
});

// Explicitly removing the className from the props so that it can be handled explicitly between container and button itself
// class intersection forces to do this and not Omit<IButtonProps, "className">
type IButtonPropsWithoutClassName = Omit<IBaseButtonProps, "className"> & (IButtonPropsWithoutChildren | IButtonPropsWithChildren);
export type IButtonWithNotificationProps = IButtonPropsWithoutClassName & VariantProps<typeof buttonNotificationDotVariants> & VariantProps<typeof notificationDotVariants> & {
  // Explicit which ones classes should be on the container (e.g. sizing classes), and which ones on the button
  containerClassName?: string;
  buttonClassName?: string;
  showDot?: boolean;
};
const ButtonWithNotification = React.forwardRef<HTMLButtonElement, IButtonWithNotificationProps>(({
  showDot,
  notificationKind,
  size,
  variant,
  containerClassName,
  buttonClassName,
  ...buttonProps
}, ref) => <div className={cn("relative w-fit", containerClassName)}>
      <Button {...buttonProps} ref={ref} className={cn(buttonClassName, "w-full")} size={size} variant={variant} />
      {showDot === true && <NotificationDot className={buttonNotificationDotVariants({
    size
  })} notificationKind={notificationKind} targetVariant={variant} />}
    </div>);
export default ButtonWithNotification;
ButtonWithNotification.displayName = "ButtonWithNotification";
export { ButtonWithNotification };