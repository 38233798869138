"use client";

import React, { useCallback, useRef, useState } from "react";
import type { ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form";
import { useAutosizeTextArea } from "../../../hooks/useAutoSizeTextArea";
import { cn } from "../../../lib/utils";
import { Button } from "../../atoms/button";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../atoms/form";
import { Textarea } from "../../atoms/textarea";
export interface ICommentTextarea<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> extends Omit<ControllerProps<TFieldValues, TName>, "render"> {
  label?: React.ReactNode;
  labelRight?: React.ReactNode;
  description?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}
export function CommentTextarea<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  label,
  labelRight,
  description,
  placeholder,
  disabled,
  className,
  onKeyDown,
  ...props
}: ICommentTextarea<TFieldValues, TName>): JSX.Element {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState<string>("");
  useAutosizeTextArea(ref, value);
  const handleKeydown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    setValue(e.currentTarget.value);
    onKeyDown?.(e);
  }, [onKeyDown]);
  const handleClick = useCallback((): void => {
    ref.current?.focus();
  }, [ref]);
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
  }, []);
  const renderInput = useCallback(({
    field
  }: {
    field: ControllerRenderProps<TFieldValues, TName>;
  }): JSX.Element => {
    const hasText = field.value != null && (field.value as string)?.trim() !== "";
    return <FormItem>
          {label != null && <div className="flex items-center justify-between">
              <FormLabel>{label}</FormLabel>
              {labelRight}
            </div>}
          <div className="group flex h-auto w-full cursor-text rounded-sm border border-border bg-input text-base ring-offset-paper file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-placeholder focus-within:border-ring focus-within:outline-none focus-within:ring-ring disabled:cursor-not-allowed disabled:opacity-50" onClick={handleClick} onMouseDown={handleMouseDown}>
            <FormControl>
              <Textarea {...field} ref={ref} className={cn("resize-none border-none text-base no-focus-outline", className)} disabled={disabled} placeholder={placeholder} onKeyDown={handleKeydown} />
            </FormControl>
            <Button className="mb-1 mr-1 self-end px-4" iconLeft="arrow-right" size="sm" title="Submit" type="submit" variant={hasText ? "primary" : "muted"} />
          </div>
          {description != null && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>;
  }, [label, labelRight, handleMouseDown, handleClick, className, placeholder, disabled, handleKeydown, description]);
  return <FormField {...props} render={renderInput} data-sentry-element="FormField" data-sentry-component="CommentTextarea" data-sentry-source-file="comment-text-area.tsx" />;
}