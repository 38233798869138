"use client";

import * as React from "react";
import { DayPicker } from "react-day-picker";
import { cn } from "../../lib/utils";
import { buttonVariants } from "./button";
import { Icon } from "./icon";
export type ICalendarProps = React.ComponentProps<typeof DayPicker>;
function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: ICalendarProps): JSX.Element {
  return <DayPicker className={cn("p-3", className)} classNames={{
    months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
    month: "space-y-4 bg-paper",
    caption: "text-base flex justify-between pt-1 px-2 relative items-center",
    caption_label: "text-base font-medium",
    nav: "space-x-1 flex items-center",
    nav_button: cn(buttonVariants({
      variant: "ghost"
    }), "size-7 bg-transparent p-0 opacity-50 hover:opacity-100"),
    table: "w-full border-collapse space-y-1",
    head_row: "flex",
    head_cell: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
    row: "flex w-full mt-2",
    cell: "text-center text-base p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
    day: cn(buttonVariants({
      variant: "ghost"
    }), "size-9 p-0 aria-selected:opacity-100"),
    day_today: "bg-accent text-accent-foreground",
    day_outside: "text-muted-foreground opacity-50",
    day_disabled: "text-muted-foreground opacity-50",
    day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
    day_hidden: "invisible",
    ...classNames
  }} components={{
    IconLeft: () => <Icon name="chevron-left" />,
    IconRight: () => <Icon name="chevron-right" />
  }} showOutsideDays={showOutsideDays} {...props} data-sentry-element="DayPicker" data-sentry-component="Calendar" data-sentry-source-file="calendar.tsx" />;
}
Calendar.displayName = "Calendar";
export { Calendar };