import { type ClassValue, clsx } from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

export function getValidChildren(
  children: React.ReactNode,
): React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>[] {
  return React.Children.toArray(children).filter(React.isValidElement);
}
