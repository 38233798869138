"use client";

import type { DialogProps } from "@radix-ui/react-dialog";
import { Command as CommandPrimitive } from "cmdk";
import * as React from "react";
import { cn } from "../../lib/utils";
import { Dialog, DialogContent } from "./dialog";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { inputVariants } from "./input";
const Command = React.forwardRef<React.ElementRef<typeof CommandPrimitive>, React.ComponentPropsWithoutRef<typeof CommandPrimitive>>(({
  className,
  ...props
}, ref) => <CommandPrimitive ref={ref} className={cn("flex size-full flex-col rounded-md bg-popover text-popover-foreground", className)} {...props} />);
Command.displayName = CommandPrimitive.displayName;
type ICommandDialogProps = DialogProps;
const CommandDialog = ({
  children,
  ...props
}: ICommandDialogProps): JSX.Element => {
  return <Dialog {...props} data-sentry-element="Dialog" data-sentry-component="CommandDialog" data-sentry-source-file="command.tsx">
      <DialogContent className="overflow-hidden p-0 shadow-lg" data-sentry-element="DialogContent" data-sentry-source-file="command.tsx">
        <Command className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:size-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:size-5" data-sentry-element="Command" data-sentry-source-file="command.tsx">
          {children}
        </Command>
      </DialogContent>
    </Dialog>;
};
const CommandInput = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Input>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
  small?: boolean;
}>(({
  className,
  small,
  ...props
}, ref) =>
// eslint-disable-next-line react/no-unknown-property
<div className={cn("flex items-center border-b px-3", small === true && "py-1")} cmdk-input-wrapper="">
    <Icon className={cn("mr-2 shrink-0 opacity-50", small === true && "size-4")} name="search" />
    <CommandPrimitive.Input ref={ref} className={cn("flex w-full rounded-md bg-transparent outline-none placeholder:text-placeholder disabled:cursor-not-allowed disabled:opacity-50", small === true ? "h-8 py-2 text-sm" : "h-11 py-3 text-lg", className)} {...props} />
  </div>);
CommandInput.displayName = CommandPrimitive.Input.displayName;
const CommandInputAsTextInput = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Input>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
  icon?: IIconNames;
  small?: boolean;
}>(({
  className,
  small,
  icon,
  ...props
}, ref) =>
// eslint-disable-next-line react/no-unknown-property
<div className={cn(inputVariants({
  size: small === true ? "small" : "default"
}), "bg-input p-0 focus-within:border-ring", className)} cmdk-input-wrapper="">
    {icon != null ? <Icon className={cn(small === true && "mt-1.5", "ml-2 shrink-0 opacity-50")} name={icon} /> : null}
    <CommandPrimitive.Input ref={ref} className={cn("flex w-full rounded-md bg-transparent px-1.5 outline-none placeholder:text-placeholder disabled:cursor-not-allowed disabled:opacity-50", small === true ? "text-base" : "text-lg")} {...props} />
  </div>);
CommandInputAsTextInput.displayName = "CommandInputAsTextInput";
const CommandList = React.forwardRef<React.ElementRef<typeof CommandPrimitive.List>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>>(({
  className,
  ...props
}, ref) => <CommandPrimitive.List ref={ref} className={cn("max-h-[300px] max-w-full overflow-y-auto overflow-x-hidden", className)} {...props} />);
CommandList.displayName = CommandPrimitive.List.displayName;
const CommandEmpty = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Empty>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>>((props, ref) => <CommandPrimitive.Empty ref={ref} className="py-6 text-center text-base" {...props} />);
CommandEmpty.displayName = CommandPrimitive.Empty.displayName;
const CommandGroup = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Group>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>>(({
  className,
  ...props
}, ref) => <CommandPrimitive.Group ref={ref} className={cn("p-1 text-ink [&_[cmdk-group-heading]]:p-2 [&_[cmdk-group-heading]]:text-sm [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground", className)} {...props} />);
CommandGroup.displayName = CommandPrimitive.Group.displayName;
const CommandSeparator = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Separator>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>>(({
  className,
  ...props
}, ref) => <CommandPrimitive.Separator ref={ref} className={cn("h-px bg-border", className)} {...props} />);
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;
const CommandItem = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Item>, React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
  small?: boolean;
}>(({
  className,
  small = false,
  ...props
}, ref) => <CommandPrimitive.Item ref={ref} className={cn("relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50", small ? "text-base" : "text-lg", className)} {...props} />);
CommandItem.displayName = CommandPrimitive.Item.displayName;
const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>): JSX.Element => {
  return <span className={cn("ml-auto text-sm tracking-widest text-muted-foreground", className)} {...props} data-sentry-component="CommandShortcut" data-sentry-source-file="command.tsx" />;
};
CommandShortcut.displayName = "CommandShortcut";
export { Command, CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandInputAsTextInput, CommandItem, CommandList, CommandSeparator, CommandShortcut };