import { Slot } from "@radix-ui/react-slot";
import * as React from "react";
import { cn } from "../../lib/utils";
import { Icon } from "./icon";
const Breadcrumb = React.forwardRef<HTMLElement, React.ComponentPropsWithoutRef<"nav"> & {
  separator?: React.ReactNode;
}>(({
  ...props
}, ref) => <nav ref={ref} aria-label="breadcrumb" className="flex items-center" {...props} />);
Breadcrumb.displayName = "Breadcrumb";
const BreadcrumbList = React.forwardRef<HTMLOListElement, React.ComponentPropsWithoutRef<"ol">>(({
  className,
  ...props
}, ref) => <ol ref={ref} className={cn("flex flex-wrap items-center gap-1.5 break-words text-base text-muted-foreground sm:gap-1.5", className)} {...props} />);
BreadcrumbList.displayName = "BreadcrumbList";
const BreadcrumbItem = React.forwardRef<HTMLLIElement, React.ComponentPropsWithoutRef<"li">>(({
  className,
  ...props
}, ref) => <li ref={ref} className={cn("inline-flex max-w-52 items-center gap-1.5", className)} {...props} />);
BreadcrumbItem.displayName = "BreadcrumbItem";
const BreadcrumbLink = React.forwardRef<HTMLAnchorElement, React.ComponentPropsWithoutRef<"a"> & {
  asChild?: boolean;
}>(({
  asChild,
  className,
  ...props
}, ref) => {
  const Comp = asChild ?? false ? Slot : "a";
  return <Comp ref={ref} className={cn("truncate transition-colors hover:text-ink", className)} {...props} />;
});
BreadcrumbLink.displayName = "BreadcrumbLink";
const BreadcrumbPage = React.forwardRef<HTMLSpanElement, React.ComponentPropsWithoutRef<"span">>(({
  className,
  ...props
}, ref) => <span ref={ref} aria-current="page" aria-disabled="true" className={cn("truncate font-normal text-ink", className)} {...props} />);
BreadcrumbPage.displayName = "BreadcrumbPage";
const BreadcrumbSeparator = ({
  children,
  className,
  ...props
}: React.ComponentProps<"li">): React.ReactElement => <li aria-hidden="true" className={cn("[&>svg]:size-3.5", className)} role="presentation" {...props} data-sentry-component="BreadcrumbSeparator" data-sentry-source-file="breadcrumbs.tsx">
    {children ?? <Icon name="chevron-right" />}
  </li>;
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";
const BreadcrumbEllipsis = ({
  className,
  ...props
}: React.ComponentProps<"span">): React.ReactElement => <span aria-hidden="true" className={cn("flex size-9 items-center justify-center", className)} role="presentation" {...props} data-sentry-component="BreadcrumbEllipsis" data-sentry-source-file="breadcrumbs.tsx">
    <Icon name="more-horizontal" data-sentry-element="Icon" data-sentry-source-file="breadcrumbs.tsx" />
    <span className="sr-only">More</span>
  </span>;
BreadcrumbEllipsis.displayName = "BreadcrumbElipssis";
export { Breadcrumb, BreadcrumbEllipsis, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator };