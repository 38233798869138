import * as ProgressPrimitive from "@radix-ui/react-progress";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
import { Skeleton } from "./skeleton";
const segmentedProgressVariants = cva("h-4 rounded-full transition-all", {
  variants: {
    color: {
      lilac: "bg-pastel-lilac-foreground",
      purple: "bg-pastel-purple-foreground",
      pink: "bg-pastel-pink-foreground",
      red: "bg-pastel-red-foreground",
      brown: "bg-pastel-brown-foreground",
      orange: "bg-pastel-orange-foreground",
      yellow: "bg-pastel-yellow-foreground",
      lime: "bg-pastel-lime-foreground",
      sage: "bg-pastel-sage-foreground",
      green: "bg-pastel-green-foreground",
      emerald: "bg-pastel-emerald-foreground",
      teal: "bg-pastel-teal-foreground",
      blue: "bg-pastel-blue-foreground",
      transparent: "bg-paper",
      white: "bg-white",
      lightGray: "bg-gray-100",
      gray: "bg-gray-200",
      primary: "bg-primary-foreground",
      secondary: "bg-secondary-foreground",
      dark: "bg-black",
      neutral: "bg-muted-background"
    }
  },
  defaultVariants: {
    color: "primary"
  }
});
type ISegmentedProgressVariantColors = VariantProps<typeof segmentedProgressVariants>["color"];
type IProgressSegment = {
  value: number;
  label?: string;
  color?: ISegmentedProgressVariantColors;
};
type ISegmentedProgress = React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
  segments: IProgressSegment[];
};
const SegmentedProgress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, ISegmentedProgress>(({
  className,
  segments,
  ...props
}, ref) => {
  const totalValue = React.useMemo(() => segments.reduce((sum, segment) => sum + segment.value, 0), [segments]);
  const scaleFactor = totalValue > 0 ? 100 / totalValue : 1;
  return <ProgressPrimitive.Root ref={ref} className={cn("flex flex-row items-center gap-px", className)} {...props}>
        {segments.map((segment, index) => {
      const percentage = segment.value * scaleFactor;
      return <div key={index} className="group relative flex flex-col space-y-1" style={{
        width: `max(25px, calc(${percentage}% - 1px))`
      }}>
              <span className="z-10 -ml-1 overflow-hidden truncate p-1 text-base text-accent-foreground group-hover:z-50 group-hover:w-fit group-hover:overflow-visible group-hover:whitespace-normal group-hover:text-nowrap group-hover:bg-paper">
                {segment.label}
              </span>
              <ProgressPrimitive.Indicator className={segmentedProgressVariants({
          color: segment.color
        })} />
            </div>;
    })}
      </ProgressPrimitive.Root>;
});
SegmentedProgress.displayName = "SegmentedProgress";
const SegmentedProgressSkeleton: React.FC<{
  withLabel?: boolean;
}> = ({
  withLabel = true
}) => {
  return <div className="w-full" data-sentry-component="SegmentedProgressSkeleton" data-sentry-source-file="segmented-progress.tsx">
      {withLabel ? <div className="mb-1 flex justify-between">
          <Skeleton className="h-4 w-16" />
          <Skeleton className="h-4 w-16" />
          <Skeleton className="h-4 w-16" />
          <span />
        </div> : null}
      <Skeleton className="h-6 w-full" data-sentry-element="Skeleton" data-sentry-source-file="segmented-progress.tsx" />
    </div>;
};
export { type IProgressSegment, type ISegmentedProgress, type ISegmentedProgressVariantColors, SegmentedProgress, SegmentedProgressSkeleton };