"use client";

import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";
import { cn } from "../../lib/utils";
const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
  small?: boolean;
}>(({
  className,
  small = false,
  ...props
}, ref) => <SwitchPrimitives.Root className={cn("peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-paper disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-gray-300", small ? "h-[16px] w-[28px]" : "h-[24px] w-[40px]", className)} {...props} ref={ref}>
    <SwitchPrimitives.Thumb className={cn("pointer-events-none block rounded-full bg-paper shadow-lg ring-0 transition-transform", small ? "size-3 data-[state=checked]:translate-x-3 data-[state=unchecked]:translate-x-0" : "size-5 data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0")} />
  </SwitchPrimitives.Root>);
Switch.displayName = SwitchPrimitives.Root.displayName;
export { Switch };