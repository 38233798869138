"use client";

import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { cn } from "../../lib/utils";
import { ScrollArea } from "./scroll-area/scroll-area";
interface ISidebarProps {
  className?: string;
  children: React.ReactNode;
}
export const SidebarContainer: React.FC<ISidebarProps> = ({
  children,
  className
}) => {
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="SidebarContainer" data-sentry-source-file="sidebar-container.tsx">
      <motion.div key="sidebar" animate={{
      x: 0
    }} className={cn("fixed inset-y-0 right-0 z-30 flex overflow-hidden bg-paper shadow-uniform-lg sm:inset-2 sm:left-auto sm:w-fit sm:min-w-[600px] sm:max-w-full sm:rounded-xl", className)} exit={{
      x: "100%"
    }} initial={{
      x: "100%"
    }} transition={{
      type: "tween"
    }} data-sentry-element="unknown" data-sentry-source-file="sidebar-container.tsx">
        <ScrollArea className={cn("flex h-full shrink-0 grow flex-col bg-paper @container", className)} data-sentry-element="ScrollArea" data-sentry-source-file="sidebar-container.tsx">
          {children}
        </ScrollArea>
      </motion.div>
    </AnimatePresence>;
};