import { cva } from "class-variance-authority";
import { cn } from "../../lib/utils";
const colors = {
  lilac: "bg-pastel-lilac-foreground",
  purple: "bg-pastel-purple-foreground",
  pink: "bg-pastel-pink-foreground",
  red: "bg-pastel-red-foreground",
  orange: "bg-pastel-orange-foreground",
  brown: "bg-pastel-brown-foreground",
  yellow: "bg-pastel-yellow-foreground",
  lime: "bg-pastel-lime-foreground",
  sage: "bg-pastel-sage-foreground",
  green: "bg-pastel-green-foreground",
  emerald: "bg-pastel-emerald-foreground",
  teal: "bg-pastel-teal-foreground",
  blue: "bg-pastel-blue-foreground",
  neutral: "bg-pastel-neutral-foreground"
} as const;
const ringColors = {
  lilac: "ring-pastel-lilac-foreground",
  purple: "ring-pastel-purple-foreground",
  pink: "ring-pastel-pink-foreground",
  red: "ring-pastel-red-foreground",
  orange: "ring-pastel-orange-foreground",
  brown: "ring-pastel-brown-foreground",
  yellow: "ring-pastel-yellow-foreground",
  lime: "ring-pastel-lime-foreground",
  sage: "ring-pastel-sage-foreground",
  green: "ring-pastel-green-foreground",
  emerald: "ring-pastel-emerald-foreground",
  teal: "ring-pastel-teal-foreground",
  blue: "ring-pastel-blue-foreground",
  neutral: "ring-pastel-neutral-foreground"
} as const;
export const EntityColorVariants = cva("relative -top-px size-3 rounded-full", {
  variants: {
    color: colors
  },
  defaultVariants: {
    color: "neutral"
  }
});
export type IEntityStateColorVariants = keyof typeof colors;
export const EntityStateColorVariantsList = Object.keys(colors) as IEntityStateColorVariants[];
interface IEntityStateColor {
  className?: string;
  selected?: boolean;
  color: IEntityStateColorVariants;
}
export const EntityStateColor = ({
  className,
  selected,
  color
}: IEntityStateColor): React.ReactNode => {
  return <span className={cn(EntityColorVariants({
    color: color
  }), selected === true && ringColors[color], selected === true && "ring-2", className)} data-sentry-component="EntityStateColor" data-sentry-source-file="entity-state-color-indicator.tsx" />;
};