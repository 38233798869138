import * as ToastPrimitives from "@radix-ui/react-toast";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
import { Icon } from "./icon";
const ToastProvider = ToastPrimitives.Provider;
const ToastViewport = React.forwardRef<React.ElementRef<typeof ToastPrimitives.Viewport>, React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>>(({
  className,
  ...props
}, ref) => <ToastPrimitives.Viewport ref={ref} className={cn("fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-2 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]", className)} {...props} />);
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;
const toastVariants = cva("group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-sm border p-3 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full", {
  variants: {
    variant: {
      default: "border bg-paper",
      error: "group border-error bg-error text-error",
      success: "border bg-paper text-success"
    }
  },
  defaultVariants: {
    variant: "default"
  }
});
const Toast = React.forwardRef<React.ElementRef<typeof ToastPrimitives.Root>, React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> & VariantProps<typeof toastVariants>>(({
  className,
  variant,
  ...props
}, ref) => {
  return <ToastPrimitives.Root ref={ref} className={cn(toastVariants({
    variant
  }), className)} {...props}>
      {props.children}
    </ToastPrimitives.Root>;
});
Toast.displayName = ToastPrimitives.Root.displayName;
const ToastAction = React.forwardRef<React.ElementRef<typeof ToastPrimitives.Action>, React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>>(({
  className,
  ...props
}, ref) => <ToastPrimitives.Action ref={ref} className={cn("inline-flex h-8 shrink-0 items-center justify-center rounded-sm border bg-transparent px-3 text-base ring-offset-paper transition-colors hover:bg-accent focus:outline-none focus:ring-1 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group-[.error]:border-muted/40 group-[.success]:border-muted/40 group-[.error]:hover:border-error/30 group-[.success]:hover:border-success/30 group-[.error]:hover:bg-error group-[.success]:hover:bg-success group-[.error]:hover:text-error group-[.success]:hover:text-success group-[.error]:focus:ring-error group-[.success]:focus:ring-success", className)} {...props} />);
ToastAction.displayName = ToastPrimitives.Action.displayName;
const ToastClose = React.forwardRef<React.ElementRef<typeof ToastPrimitives.Close>, React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>>(({
  className,
  ...props
}, ref) => <ToastPrimitives.Close ref={ref} className={cn("absolute right-2 top-2 rounded-sm p-1 text-muted opacity-0 transition-opacity hover:text-ink focus:opacity-100 focus:outline-none focus:ring-1 group-hover:opacity-100 group-[.error]:text-red-300 group-[.success]:text-green-300 group-[.error]:hover:text-red-50 group-[.success]:hover:text-green-50 group-[.error]:focus:ring-red-400 group-[.success]:focus:ring-green-400 group-[.error]:focus:ring-offset-red-600 group-[.success]:focus:ring-offset-green-600", className)} toast-close="" {...props}>
    <Icon name="x" />
  </ToastPrimitives.Close>);
ToastClose.displayName = ToastPrimitives.Close.displayName;
const ToastTitle = React.forwardRef<React.ElementRef<typeof ToastPrimitives.Title>, React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>>(({
  className,
  ...props
}, ref) => <ToastPrimitives.Title ref={ref} className={cn("text-base font-medium", className)} {...props} />);
ToastTitle.displayName = ToastPrimitives.Title.displayName;
const ToastDescription = React.forwardRef<React.ElementRef<typeof ToastPrimitives.Description>, React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>>(({
  className,
  ...props
}, ref) => <ToastPrimitives.Description ref={ref} className={cn("text-base opacity-90", className)} {...props} />);
ToastDescription.displayName = ToastPrimitives.Description.displayName;
type IToastProps = React.ComponentPropsWithoutRef<typeof Toast>;
type IToastActionElement = React.ReactElement<typeof ToastAction>;
export { Toast, ToastAction, type IToastActionElement as ToastActionElement, ToastClose, ToastDescription, type IToastProps as ToastProps, ToastProvider, ToastTitle, ToastViewport };