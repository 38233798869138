"use client";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
import { Icon } from "./icon";
const checkboxVariants = cva("peer shrink-0 rounded-sm border border-border bg-paper ring-offset-paper hover:bg-muted focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-2 active:scale-95 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-primary data-[state=indeterminate]:border-primary data-[state=checked]:bg-primary data-[state=indeterminate]:bg-primary data-[state=checked]:text-primary-foreground data-[state=indeterminate]:text-primary-foreground", {
  variants: {
    size: {
      default: "size-4",
      lg: "size-6"
    }
  },
  defaultVariants: {
    size: "default"
  }
});
const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
  size?: "default" | "lg";
}>(({
  className,
  size,
  ...props
}, ref) => <CheckboxPrimitive.Root ref={ref} className={cn(checkboxVariants({
  size
}), className)} {...props}>
    <CheckboxPrimitive.Indicator className={cn("flex items-center justify-center text-current")}>
      <Icon className="size-3" name={props.checked === true ? "check" : "minus"} />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;
export { Checkbox };