import React from "react";
import { cn } from "../../lib/utils";
import { Skeleton } from "./skeleton";
export interface ITableSkeleton {
  className?: string;
  numberCols: number;
  numberRows: number;
  density: "compact" | "default" | "airy";
}
const randomLookingLcg = (row: number, col: number, a = 11, c = 23, m = 5): number => {
  if (row % m === 0) {
    return (col * a + c) % m / m;
  }
  if (col % m === 0) {
    return (row * a + c) % m / m;
  }
  return (row * col * a + c) % m / m;
};
const DEFAULT_HEADER_HEIGHT = 42;
const DEFAULT_ROW_HEIGHT = 36;
export const TableSkeleton: React.FC<ITableSkeleton> = ({
  className,
  numberCols,
  numberRows,
  density
}) => {
  const densityClass = {
    compact: "p-1",
    default: "p-2",
    airy: "p-3"
  }[density];
  return <div className={cn("overflow-hidden rounded border border-border bg-paper outline-none", className)} data-sentry-component="TableSkeleton" data-sentry-source-file="table-skeleton.tsx">
      <div className="sticky inset-x-0 top-0 z-20" style={{
      minWidth: `${numberCols * 200}px`,
      width: "100%"
    }}>
        <div className="flex border-b border-border bg-muted-background" style={{
        height: DEFAULT_HEADER_HEIGHT
      }}>
          {Array.from({
          length: numberCols
        }).map((_, index) => <div key={index} className={cn(densityClass, "w-[200px] shrink-0")}>
              <Skeleton className="h-3" style={{
            width: 50 + 100 * randomLookingLcg(20, index + 1)
          }} />
            </div>)}
        </div>
      </div>
      <div className="relative" style={{
      height: numberRows * DEFAULT_ROW_HEIGHT,
      minWidth: `${numberCols * 200}px`,
      width: "100%"
    }}>
        {Array.from({
        length: numberRows
      }).map((_, rowIndex) => <div key={rowIndex} className="flex border-b border-border" style={{
        height: DEFAULT_ROW_HEIGHT,
        top: rowIndex * DEFAULT_ROW_HEIGHT,
        position: "absolute",
        left: 0,
        width: "100%"
      }}>
            {Array.from({
          length: numberCols
        }).map((__, colIndex) => <div key={colIndex} className={cn(densityClass, "w-[200px] shrink-0")}>
                <Skeleton className="h-4" style={{
            width: 50 + 100 * randomLookingLcg(rowIndex + 1, colIndex + 1)
          }} />
              </div>)}
          </div>)}
      </div>
    </div>;
};