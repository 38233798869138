import { useEffect } from "react";

// Updates the height of a <textarea> when the value changes.
export const useAutosizeTextArea = (textAreaRef: React.RefObject<HTMLTextAreaElement>, value: string): void => {
  useEffect(() => {
    if (textAreaRef.current == null) {
      return;
    }
    const current = textAreaRef.current;
    const computeHeight = (): void => {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      current.style.height = "0px";

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      current.style.height = `${current.scrollHeight}px`;
    };
    requestAnimationFrame(computeHeight);
    const resizeObserver = new ResizeObserver(computeHeight);
    resizeObserver.observe(current);
    return (): void => {
      resizeObserver.disconnect();
    };
  }, [textAreaRef, value]);
};