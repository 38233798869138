import React, { useCallback } from "react";
import * as RPNInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
export type { Country } from "react-phone-number-input";
import { cn } from "../../lib/utils";
import { buttonVariants } from "./button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "./command";
import { Icon } from "./icon";
import { Input } from "./input";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { ScrollArea } from "./scroll-area/scroll-area";
import { ScrollAreaProvider } from "./scroll-area/scroll-area-provider";
type IPhoneInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "value"> & Omit<RPNInput.Props<typeof RPNInput.default>, "onChange" | "onCountryChange"> & {
  onChange?: (value: RPNInput.Value) => void;
  variant?: "input" | "text";
  small?: boolean;
  onCountryChange?: (value: RPNInput.Country) => void | Promise<void>;
};
export const PhoneInput = React.memo(React.forwardRef<React.ElementRef<typeof RPNInput.default>, IPhoneInputProps>(({
  className,
  onChange,
  variant = "input",
  small = false,
  ...props
}, ref) => {
  const handleOnChange = useCallback((value: RPNInput.Value): void => {
    if (onChange && value != null) {
      onChange(value);
    }
  }, [onChange]);
  const countrySelectComponent = useCallback((p: ICountrySelectProps): React.ReactNode => {
    const showCountrySelect = variant === "text" && props.value !== undefined || variant === "input";
    if (showCountrySelect) {
      return <CountrySelect {...p} variant={variant} />;
    }
    return null;
  }, [variant, props.value]);
  return <RPNInput.default ref={ref} className={cn("flex", small && "text-base", variant === "text" && "h-full items-center gap-x-2 overflow-hidden", props.disabled === true && "pointer-events-none", className)} countrySelectComponent={countrySelectComponent} flagComponent={FlagComponent} initialValueFormat="national" inputComponent={variant === "text" ? TableCellPhoneInputTextField : PhoneInputTextField} onChange={handleOnChange} {...props} />;
}));
PhoneInput.displayName = "PhoneInput";
type ICountrySelectOption = {
  label: string;
  value: RPNInput.Country;
};
type ICountrySelectProps = {
  value: RPNInput.Country;
  onChange: (value: RPNInput.Country | null) => void;
  options: ICountrySelectOption[];
  variant?: "input" | "text";
};
const CountrySelect = ({
  value,
  onChange,
  options,
  variant = "input"
}: ICountrySelectProps): JSX.Element => {
  const handleSelect = useCallback((country: RPNInput.Country | null) => (): void => {
    onChange(country);
  }, [onChange]);
  return <Popover data-sentry-element="Popover" data-sentry-component="CountrySelect" data-sentry-source-file="phone-input.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="phone-input.tsx">
        {variant === "text" ? <span className="flex items-center">
            <FlagComponent country={value} countryName={value} />
          </span> : <PhoneInputButton type="button">
            <FlagComponent country={value} countryName={value} />
            <Icon className="-mr-2 size-4 opacity-50" name="chevrons-up-down" />
          </PhoneInputButton>}
      </PopoverTrigger>
      <PopoverContent align="start" className="p-0" side="bottom" sideOffset={4} data-sentry-element="PopoverContent" data-sentry-source-file="phone-input.tsx">
        <Command data-sentry-element="Command" data-sentry-source-file="phone-input.tsx">
          <CommandList data-sentry-element="CommandList" data-sentry-source-file="phone-input.tsx">
            <ScrollAreaProvider direction="vertical" data-sentry-element="ScrollAreaProvider" data-sentry-source-file="phone-input.tsx">
              <ScrollArea className="h-72" data-sentry-element="ScrollArea" data-sentry-source-file="phone-input.tsx">
                <CommandInput className="text-base" placeholder="Search country..." data-sentry-element="CommandInput" data-sentry-source-file="phone-input.tsx" />
                <CommandEmpty data-sentry-element="CommandEmpty" data-sentry-source-file="phone-input.tsx">No country found.</CommandEmpty>
                <CommandGroup data-sentry-element="CommandGroup" data-sentry-source-file="phone-input.tsx">
                  {options.filter(x => x.value).map(option => <CommandItem key={option.value} className="cursor-pointer gap-2" onSelect={handleSelect(option.value)}>
                        <FlagComponent country={option.value} countryName={option.label} />
                        <span className="flex-1 text-base">{option.label}</span>
                        {option.value ? <span className="text-sm text-muted-foreground">{`+${RPNInput.getCountryCallingCode(option.value)}`}</span> : null}
                        <Icon className={cn("ml-auto size-4", option.value === value ? "opacity-100" : "opacity-0")} name="check" />
                      </CommandItem>)}
                </CommandGroup>
              </ScrollArea>
            </ScrollAreaProvider>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>;
};
const FlagComponent = ({
  country,
  countryName
}: RPNInput.FlagProps): JSX.Element => {
  if (country === undefined) {
    return <Icon className="size-3 text-muted-foreground" name="globe" />;
  }
  const Flag = flags[country];
  return <span className="flex size-3.5 overflow-hidden rounded-sm" data-sentry-component="FlagComponent" data-sentry-source-file="phone-input.tsx">{Flag ? <Flag title={countryName} /> : null}</span>;
};
FlagComponent.displayName = "FlagComponent";
const PhoneInputButton = React.forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(({
  ...props
}, ref): JSX.Element => {
  return <button ref={ref} className={cn(buttonVariants({
    variant: "outline",
    size: "lg"
  }), "h-10 gap-1 rounded-e-none rounded-s-lg border-r-0 border-border bg-paper px-3 hover:bg-muted-background focus-visible:ring-ring active:bg-muted-background")} {...props} />;
});
PhoneInputButton.displayName = "PhoneInputButton";
const PhoneInputTextField = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(({
  ...props
}, ref): JSX.Element => {
  return <Input ref={ref} {...props} className={cn("rounded-e-lg rounded-s-none", props.className)} />;
});
PhoneInputTextField.displayName = "PhoneInputTextField";
const TableCellPhoneInputTextField = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(({
  ...props
}, ref): JSX.Element => {
  return <input ref={ref} {...props} className={cn("w-full truncate bg-transparent outline-none", props.className)} />;
});
TableCellPhoneInputTextField.displayName = "TableCellPhoneInputTextField";