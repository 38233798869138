"use client";

import * as React from "react";
import { cn } from "../../lib/utils";
import { useScrollAreaContext } from "./scroll-area/scroll-area-provider";
interface ISidebarFooterProps {
  className?: string;
  children: React.ReactNode;
}
export const SidebarFooter: React.FC<ISidebarFooterProps> = ({
  className,
  children
}) => {
  const {
    scrollState
  } = useScrollAreaContext();
  return <div className={cn("border-t bg-muted-background p-4 transition-shadow", scrollState !== "end" ? "shadow-[0px_-2px_8px_0px_hsl(var(--gray-300))]" : "", className)} data-sentry-component="SidebarFooter" data-sentry-source-file="sidebar-footer.tsx">
      {children}
    </div>;
};