import Link from "next/link";
import type { NextRouter } from "next/router";
import { useRouter } from "next/router";
import type { PropsWithChildren } from "react";
import React from "react";
import type { IRoute } from "./api";
interface IEnhancedRouter<P extends object> {
  LinkRenderer: React.FC<P>;
  navigate: (props: P, router?: NextRouter) => Promise<boolean>;
  getHref: (props: P) => IRoute;
}
export function enhancedLinkRenderer<P extends object, T extends IRoute>(hrefGenerator: (props: P) => T): IEnhancedRouter<P> {
  const LinkRenderer: React.FC<PropsWithChildren<P>> = ({
    children,
    ...props
  }) => <Link href={hrefGenerator(props as P)} passHref={true} data-sentry-element="Link" data-sentry-component="LinkRenderer" data-sentry-source-file="enhancedLinkRenderer.tsx">
      {children}
    </Link>;
  const navigate = async (props: P, router?: NextRouter): Promise<boolean> => {
    const href = hrefGenerator(props);
    if (router) {
      return router.push(href);
    } else if (typeof window !== "undefined") {
      const {
        default: Router
      } = await import("next/router");
      return Router.push(href);
    }
    // eslint-disable-next-line no-console
    console.warn("Navigation attempted on server side");
    return false;
  };
  return {
    LinkRenderer,
    navigate,
    getHref: hrefGenerator
  };
}
export function useEnhancedLinkRenderer<P extends object, T extends IRoute>(routeCreator: (props: P, router: NextRouter) => T): IEnhancedRouter<P> {
  const router = useRouter();
  const hrefGenerator = (props: P): T => routeCreator(props, router);
  return enhancedLinkRenderer(hrefGenerator);
}