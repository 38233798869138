"use client";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";
import { cn } from "../../lib/utils";
const TooltipProvider = TooltipPrimitive.Provider;
const Tooltip = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipPortal = TooltipPrimitive.Portal;
const TooltipContent = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>>(({
  className,
  sideOffset = 4,
  ...props
}, ref) => <TooltipPortal>
    <TooltipPrimitive.Content ref={ref} className={cn("z-50 cursor-default overflow-hidden rounded-md border border-border bg-paper px-3 py-1.5 text-sm text-ink shadow animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1", className)} sideOffset={sideOffset} {...props} />
  </TooltipPortal>);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
export type ITooltipContentProps = TooltipPrimitive.TooltipContentProps;
export { Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger };