import React, { useCallback, useState } from "react";
import { cn } from "../../lib/utils";
import { Button } from "../atoms/button";
export interface IWorkspaceLayout {
  callout?: React.ReactNode;
  leftSidebarHeader?: React.ReactNode;
  leftSidebarFooter?: React.ReactNode;
  leftSidebarContent: React.ReactNode;
  rightSidebarContent?: React.ReactNode | null;
  mainPanel: React.ReactNode;
  mainPanelHeader?: React.ReactNode;
}
export const WorkspaceLayout: React.FC<IWorkspaceLayout> = ({
  callout,
  leftSidebarHeader,
  leftSidebarFooter,
  leftSidebarContent,
  rightSidebarContent,
  mainPanel,
  mainPanelHeader
}) => {
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(window?.innerWidth > 768);
  const handleToggleSidebar = useCallback(() => {
    setIsLeftSidebarOpen(isOpen => !isOpen);
  }, []);
  const commonHeaderClass = cn("flex h-12 w-full shrink-0 items-center px-2");
  return <main className="flex h-screen w-screen flex-col overflow-hidden bg-paper text-ink" data-sentry-component="WorkspaceLayout" data-sentry-source-file="WorkspaceLayout.tsx">
      {callout != null ? <div className="flex h-8 w-full">{callout}</div> : null}
      <div className="relative flex size-full flex-row overflow-hidden">
        <div className={cn("absolute inset-y-0 left-0 flex w-64 flex-col items-stretch overflow-y-auto bg-muted-background transition-all", isLeftSidebarOpen ? "translate-x-0" : "-translate-x-full")}>
          <div className={commonHeaderClass}>{leftSidebarHeader}</div>
          <div className="shrink-0 grow">{leftSidebarContent}</div>
          {leftSidebarFooter}
        </div>

        <div className={cn("flex h-full shrink-0 grow flex-col bg-muted-background", isLeftSidebarOpen ? "ml-64" : "ml-0")}>
          {mainPanelHeader != null ? <div className={cn(commonHeaderClass, "flex h-12 items-center space-x-2")}>
              <Button iconLeft={isLeftSidebarOpen ? "chevrons-left" : "chevrons-right"} size="sm" variant="ghost" onClick={handleToggleSidebar} />
              {mainPanelHeader}
            </div> : null}
          <div className={cn("flex size-full overflow-hidden rounded-xl bg-paper", isLeftSidebarOpen ? "max-w-[calc(100dvw-16rem)]" : "max-w-[100dvw]", callout != null && mainPanelHeader != null ? "max-h-[calc(100dvh-4.5rem)]" : undefined, callout == null && mainPanelHeader != null ? "max-h-[calc(100dvh-3rem)]" : undefined, callout != null && mainPanelHeader === null ? "max-h-dvh" : undefined)}>
            {mainPanel}
          </div>
        </div>

        <div className={cn("relative inset-y-0 w-0 min-w-0 flex-col items-stretch overflow-y-auto bg-muted-background transition-all", rightSidebarContent != null ? "md:w-64 md:min-w-64" : undefined)}>
          {rightSidebarContent}
        </div>
      </div>
    </main>;
};