import { sortBy } from "lodash";
import React from "react";
import { useMemoDeepCompare } from "../../../hooks/useMemoDeepCompare";
import { cn } from "../../../lib/utils";
import { CommentThreadSkeleton } from "./comment-thread-skeleton";
import { CommentTimestamp } from "./comment-timestamp";
export interface IComment {
  id: string;
  author: string | JSX.Element | undefined;
  authorImage: JSX.Element | undefined;
  at: Date;
  commentText: string;
}
export interface ICommentThreadEvent<T extends object> {
  id: string;
  author: string | JSX.Element;
  authorImage: JSX.Element | undefined;
  at: Date;
  value: T;
}
interface ICommentThreadCommentEntry extends IComment {
  entryType: "comment";
  commentType: "default" | "sending" | "failed";
}
interface ICommentThreadEventEntry<T extends object> extends ICommentThreadEvent<T> {
  entryType: "event";
}
type IEntry<T extends object> = ICommentThreadCommentEntry | ICommentThreadEventEntry<T>;
export type ICommentThreadProps<T extends object> = {
  className?: string;
  /**
   * Returns a promise so that the component can optimistically show the comment before it reappears in the existing comment
   * and hide it when it does.
   */
  comments: IComment[];
  events: ICommentThreadEvent<T>[];
  eventRenderer: undefined | ((eventValue: T) => JSX.Element);
  sendingComments: IComment[];
  failedComments: IComment[];

  // TODO: add a tag provider for @user or @entity
  isLoading?: boolean;
};
export function CommentThread<T extends object>({
  className,
  comments,
  events,
  eventRenderer,
  sendingComments,
  failedComments,
  isLoading
}: ICommentThreadProps<T>): JSX.Element {
  const entries: IEntry<T>[] = useMemoDeepCompare(() => sortBy(comments.map(c => ({
    ...c,
    entryType: "comment" as const,
    commentType: "default" as const
  }) as IEntry<T>).concat(events.map(e => ({
    ...e,
    entryType: "event" as const
  }))).concat(failedComments.map(c => ({
    ...c,
    entryType: "comment" as const,
    commentType: "failed" as const
  }))), entry => entry.at.getTime()), [comments, events, failedComments]);
  const allEntries = entries.concat(sendingComments.map(c => ({
    ...c,
    entryType: "comment" as const,
    commentType: "sending" as const
  })));
  const renderComment = (entry: IEntry<T>): React.ReactNode => {
    const getContent = (): React.ReactNode => {
      switch (entry.entryType) {
        case "comment":
          if (entry.commentType === "sending") {
            return <div className="text-sm text-primary">Sending...</div>;
          }
          if (entry.commentType === "failed") {
            return <div className="text-sm text-error">Failed to send...</div>;
          }
          return <div className="flex py-2 align-top">
              <div className="mr-2 shrink-0 overflow-hidden rounded-md">{entry.authorImage}</div>
              <div className="grow">
                <div className="flex items-center justify-start text-base">
                  <div className="mr-1 font-bold">{entry.author}</div>
                  <span className="mr-1 font-bold">·</span>
                  <div className="text-xs text-muted-foreground">
                    <CommentTimestamp timestamp={entry.at} />
                  </div>
                </div>
                <div className="text-base">{entry.commentText}</div>
              </div>
            </div>;
        case "event":
          return eventRenderer ? eventRenderer(entry.value) : null;
      }
    };
    return <div key={entry.id} className="text-sm" data-sentry-component="renderComment" data-sentry-source-file="comment-thread.tsx">
        {getContent()}
      </div>;
  };
  if (isLoading === true) {
    return <CommentThreadSkeleton />;
  }
  return <div className={cn(className, "items-stretch bg-paper")} data-sentry-component="CommentThread" data-sentry-source-file="comment-thread.tsx">
      <div>
        <div className="border-b border-accent px-3 py-4 text-base">Updates</div>
        <div className="mt-1 p-4">
          {allEntries.length === 0 ? <div className="text-sm text-muted-foreground">No updates yet for this item.</div> : <div className="-mt-2 flex flex-col space-y-2">{allEntries.map(renderComment)}</div>}
        </div>
      </div>
    </div>;
}
CommentThread.displayName = "CommentThread";