import React, { createContext, type PropsWithChildren, useContext, useMemo } from "react";
import { useScrollState } from "../../../hooks/useScrollState";
type IScrollContainerContext = ReturnType<typeof useScrollState> & {
  direction: "horizontal" | "vertical" | "both";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
const ScrollAreaContext = createContext<IScrollContainerContext | null>(null);
export const useScrollAreaContext = (): IScrollContainerContext => {
  const context = useContext(ScrollAreaContext);
  if (context === null) {
    throw new Error("useScrollAreaContext must be used within a ScrollAreaProvider");
  }
  return context;
};
interface IScrollContainerProvider extends PropsWithChildren {
  direction: "horizontal" | "vertical" | "both";
}
export const ScrollAreaProvider: React.FC<IScrollContainerProvider> = ({
  children,
  direction
}) => {
  const {
    scrollState,
    setScrollState,
    handleScroll
  } = useScrollState(direction);
  const contextValue = useMemo(() => ({
    direction,
    scrollState,
    setScrollState,
    handleScroll
  }), [direction, scrollState, setScrollState, handleScroll]);
  return <ScrollAreaContext.Provider value={contextValue} data-sentry-element="unknown" data-sentry-component="ScrollAreaProvider" data-sentry-source-file="scroll-area-provider.tsx">{children}</ScrollAreaContext.Provider>;
};