import type { Table } from "@tanstack/react-table";
import { useCallback } from "react";
import { Button } from "../../atoms/button";
import { Icon } from "../../atoms/icon";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../atoms/select";
interface ITablePaginationProps<TData> {
  table: Table<TData>;
  showSelected?: boolean;
}
export function TablePagination<TData>({
  table,
  showSelected
}: ITablePaginationProps<TData>): JSX.Element {
  const handleSetPageSize = useCallback((pageSize: string) => {
    table.setPageSize(Number(pageSize));
  }, [table]);
  const handleSetPageIndex = useCallback((pageIndex: number) => (): void => {
    table.setPageIndex(pageIndex);
  }, [table]);
  const handleNextPage = useCallback(() => {
    table.nextPage();
  }, [table]);
  const handlePreviousPage = useCallback(() => {
    table.previousPage();
  }, [table]);
  return <div className="flex justify-end" data-sentry-component="TablePagination" data-sentry-source-file="TablePagination.tsx">
      <div className="flex items-center justify-between px-2">
        {showSelected === true && <div className="hidden flex-1 text-sm text-muted-foreground lg:flex">
            {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s)
            selected.
          </div>}
        <div className="flex items-center space-x-6 lg:space-x-8">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-normal">Rows per page</p>
            <Select value={`${table.getState().pagination.pageSize}`} onValueChange={handleSetPageSize} data-sentry-element="Select" data-sentry-source-file="TablePagination.tsx">
              <SelectTrigger className="h-8 w-[70px]" data-sentry-element="SelectTrigger" data-sentry-source-file="TablePagination.tsx">
                <SelectValue placeholder={table.getState().pagination.pageSize} data-sentry-element="SelectValue" data-sentry-source-file="TablePagination.tsx" />
              </SelectTrigger>
              <SelectContent side="top" data-sentry-element="SelectContent" data-sentry-source-file="TablePagination.tsx">
                {[10, 20, 30, 40, 50].map(pageSize => <SelectItem key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                  </SelectItem>)}
              </SelectContent>
            </Select>
          </div>
          <div className="flex w-[100px] items-center justify-center text-sm font-normal">
            Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </div>
          <div className="flex items-center space-x-2">
            <Button className="hidden size-8 p-0 lg:flex" disabled={!table.getCanPreviousPage()} variant="ghost" onClick={handleSetPageIndex(0)} data-sentry-element="Button" data-sentry-source-file="TablePagination.tsx">
              <span className="sr-only">Go to first page</span>
              <Icon name="chevrons-left" data-sentry-element="Icon" data-sentry-source-file="TablePagination.tsx" />
            </Button>
            <Button className="size-8 p-0" disabled={!table.getCanPreviousPage()} variant="ghost" onClick={handlePreviousPage} data-sentry-element="Button" data-sentry-source-file="TablePagination.tsx">
              <span className="sr-only">Go to previous page</span>
              <Icon name="chevron-left" data-sentry-element="Icon" data-sentry-source-file="TablePagination.tsx" />
            </Button>
            <Button className="size-8 p-0" disabled={!table.getCanNextPage()} variant="ghost" onClick={handleNextPage} data-sentry-element="Button" data-sentry-source-file="TablePagination.tsx">
              <span className="sr-only">Go to next page</span>
              <Icon name="chevron-right" data-sentry-element="Icon" data-sentry-source-file="TablePagination.tsx" />
            </Button>
            <Button className="hidden size-8 p-0 lg:flex" disabled={!table.getCanNextPage()} variant="ghost" onClick={handleSetPageIndex(table.getPageCount() - 1)} data-sentry-element="Button" data-sentry-source-file="TablePagination.tsx">
              <span className="sr-only">Go to last page</span>
              <Icon name="chevrons-right" data-sentry-element="Icon" data-sentry-source-file="TablePagination.tsx" />
            </Button>
          </div>
        </div>
      </div>
    </div>;
}