import React from "react";
import { cn } from "../../../lib/utils";
import { Skeleton } from "../../atoms/skeleton";
type ISkeletonCommentData = {
  type: "comment";
  id: string;
  name: string;
  content: string;
} | {
  type: "event";
  id: string;
};
const SKELETON_COMMENT_DATA: Array<ISkeletonCommentData> = [{
  type: "comment",
  id: "first_comment",
  name: "First Comment",
  content: "Lorem ipsum dolor sit amet "
}, {
  type: "event",
  id: "event"
}, {
  type: "comment",
  id: "second_comment",
  name: "second Comment longer",
  content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut diam eget libero."
}];
export const CommentThreadSkeleton: React.FC<{
  className?: string;
}> = ({
  className
}) => {
  return <div className={cn(className, "items-stretch rounded border border-border bg-paper")} data-sentry-component="CommentThreadSkeleton" data-sentry-source-file="comment-thread-skeleton.tsx">
      <div>
        <div className="border-b border-accent px-3 py-4 text-base">Updates</div>
        <div className="mt-1 p-4">
          {SKELETON_COMMENT_DATA.map(skeletonData => <CommentSkeleton key={skeletonData.id} skeletonData={skeletonData} />)}
        </div>
      </div>
    </div>;
};
const CommentSkeleton: React.FC<{
  skeletonData: ISkeletonCommentData;
}> = ({
  skeletonData
}) => {
  if (skeletonData.type === "comment") {
    return <div key={skeletonData.id} className="flex py-2 align-top">
        <Skeleton className="mr-2 size-6 shrink-0 overflow-hidden rounded-md" />
        <div className="grow">
          <div className="flex items-center justify-start text-base">
            <Skeleton className="mr-1 font-bold">{skeletonData.name}</Skeleton>
            <span className="mr-1 font-bold">·</span>
            <Skeleton className="text-xs">timestampe</Skeleton>
          </div>
          <Skeleton className="mt-1 text-base">{skeletonData.content}</Skeleton>
        </div>
      </div>;
  }
  return <div className="flex items-center space-x-1 text-sm" data-sentry-component="CommentSkeleton" data-sentry-source-file="comment-thread-skeleton.tsx">
      <Skeleton className="h-4 w-16" data-sentry-element="Skeleton" data-sentry-source-file="comment-thread-skeleton.tsx" />
      <span className="font-bold">·</span>
      <Skeleton className="h-4 w-24" data-sentry-element="Skeleton" data-sentry-source-file="comment-thread-skeleton.tsx" />
      <span className="font-bold">·</span>
      <Skeleton className="h-4 w-10" data-sentry-element="Skeleton" data-sentry-source-file="comment-thread-skeleton.tsx" />
    </div>;
};