import { useEffect, useRef, useState } from "react";
export function useStateWithDependency<T>(dependency: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(dependency);
  const ref = useRef<T>(dependency);
  useEffect(() => {
    if (ref.current !== dependency) {
      setState(dependency);
    }
    ref.current = dependency;
  }, [ref, dependency]);
  return [state, setState];
}