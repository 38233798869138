import React from "react";
import { cn } from "../../lib/utils";
export const Spinner: React.FC<{
  className?: string;
}> = ({
  className
}) => <span className={cn("inline-block size-4 text-current", className)} data-sentry-component="Spinner" data-sentry-source-file="spinner.tsx">
    <svg viewBox="0 0 100 100" data-sentry-element="svg" data-sentry-source-file="spinner.tsx">
      <path d="M 50 20 L 80 70 L 20 70 Z" fill="none" stroke="currentColor" strokeDasharray="30" strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" data-sentry-element="path" data-sentry-source-file="spinner.tsx">
        <animate attributeName="stroke-dashoffset" dur="3s" from="180" repeatCount="indefinite" to="0" data-sentry-element="animate" data-sentry-source-file="spinner.tsx" />
        <animateTransform attributeName="transform" attributeType="XML" dur="3s" from="0 50 53" repeatCount="indefinite" to="360 50 53" type="rotate" data-sentry-element="animateTransform" data-sentry-source-file="spinner.tsx" />
      </path>
    </svg>
  </span>;