import React from "react";
import { cn } from "../../../lib/utils";
import { Spinner } from "../../atoms/spinner";
export interface ILoader {
  text?: string | null;
  className?: string;
}
export const Loader: React.FC<ILoader> = ({
  className,
  text = "Loading..."
}) => <div className={cn("flex items-center text-sm text-muted-foreground", className)} data-sentry-component="Loader" data-sentry-source-file="Loader.tsx">
    <Spinner className="mr-2" data-sentry-element="Spinner" data-sentry-source-file="Loader.tsx" />
    {text}
  </div>;