import { useCallback } from "react";
import type { IIconNames } from "../atoms/icon";
import { Icon } from "../atoms/icon";
import { ToggleGroup, ToggleGroupItem } from "../atoms/toggle-group";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../atoms/tooltip";
export type IViewType = "table" | "groupedTable" | "board" | "cards" | "groupedTableOrCards";
const VIEW_TYPE_OPTIONS: Array<{
  viewType: IViewType;
  viewTypeIcon: IIconNames;
  viewTypeLabel: string;
}> = [{
  viewType: "table",
  viewTypeIcon: "sheet",
  viewTypeLabel: "Table"
}, {
  viewType: "groupedTable",
  viewTypeIcon: "server",
  // Change to table-rows-split after upgrading lucid?
  viewTypeLabel: "Grouped table"
}, {
  viewType: "board",
  viewTypeIcon: "kanban-square",
  viewTypeLabel: "Board"
}, {
  viewType: "cards",
  viewTypeIcon: "layout-list",
  viewTypeLabel: "Cards"
}, {
  viewType: "groupedTableOrCards",
  viewTypeIcon: "sparkles",
  viewTypeLabel: "Rich grouped view"
}];
interface IViewTypeSwitcherProps {
  viewType: IViewType;
  setViewType: (viewType: IViewType) => void;
}
export const ViewTypeSwitcher: React.FC<IViewTypeSwitcherProps> = ({
  viewType,
  setViewType
}) => {
  const handleViewTypeChange = useCallback((value: string): void => setViewType(value as IViewType), [setViewType]);
  return <div className="flex items-center gap-x-2 px-4 py-2" data-sentry-component="ViewTypeSwitcher" data-sentry-source-file="view-type-switcher.tsx">
      <span className="mr-1 font-mono text-xs uppercase text-muted-foreground">View type</span>
      <ToggleGroup size="sm" type="single" value={viewType} onValueChange={handleViewTypeChange} data-sentry-element="ToggleGroup" data-sentry-source-file="view-type-switcher.tsx">
        {VIEW_TYPE_OPTIONS.map(option => <TooltipProvider key={option.viewType} delayDuration={300}>
            <Tooltip>
              <TooltipTrigger>
                <ToggleGroupItem aria-label={option.viewTypeLabel} value={option.viewType}>
                  <Icon name={option.viewTypeIcon} />
                </ToggleGroupItem>
              </TooltipTrigger>
              <TooltipContent>{option.viewTypeLabel}</TooltipContent>
            </Tooltip>
          </TooltipProvider>)}
      </ToggleGroup>
    </div>;
};