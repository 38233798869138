import React from "react";
import { cn } from "../../lib/utils";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../atoms/breadcrumbs";
import { Header } from "../molecules/header/Header";
import type { IBreadcrumbNavItem } from "./api";
export interface IAbstractDashboardLayout {
  className?: string;
  breadcrumbs: IBreadcrumbNavItem[];
  actions?: React.ReactNode;
  leftPanel?: React.ReactNode;
  mainPanel: React.ReactNode;
}
export const AbstractDashboardLayout: React.FC<IAbstractDashboardLayout> = ({
  className,
  breadcrumbs,
  mainPanel,
  leftPanel,
  actions
}) => {
  const breadcrumbContent = <Breadcrumb>
      <BreadcrumbList>
        {breadcrumbs.map((breadcrumb, idx) => <React.Fragment key={idx}>
            {idx > 0 && <BreadcrumbSeparator />}
            <BreadcrumbItem>
              {breadcrumb.linkRenderer ? <BreadcrumbLink>
                  {breadcrumb.linkRenderer({
              children: breadcrumb.text
            })}
                </BreadcrumbLink> : <BreadcrumbPage>{breadcrumb.text}</BreadcrumbPage>}
            </BreadcrumbItem>
          </React.Fragment>)}
      </BreadcrumbList>
    </Breadcrumb>;
  return <main className={cn("flex h-screen w-screen flex-col overflow-hidden text-ink", className)} data-sentry-component="AbstractDashboardLayout" data-sentry-source-file="AbstractDashboardLayout.tsx">
      <Header leftContent={breadcrumbContent} rightContent={actions} data-sentry-element="Header" data-sentry-source-file="AbstractDashboardLayout.tsx" />
      <div className="flex h-0 max-h-full shrink grow">
        {leftPanel != null && <aside className="flex h-full flex-col">{leftPanel}</aside>}
        <div className="flex w-full grow flex-col">{mainPanel}</div>
      </div>
    </main>;
};