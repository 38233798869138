"use client";

import * as React from "react";
import { cn } from "../../lib/utils";
interface ISidebarContentProps {
  className?: string;
  children: React.ReactNode;
}
export const SidebarContent = React.forwardRef<React.ElementRef<React.ForwardRefExoticComponent<React.RefAttributes<HTMLDivElement>>>, ISidebarContentProps>(({
  className,
  children
}, ref) => {
  return <div ref={ref} className={cn("flex flex-col bg-paper p-4 @container", className)}>
      {children}
    </div>;
});
SidebarContent.displayName = "SidebarContent";