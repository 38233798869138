"use client";

import { Icon } from "./icon";
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from "./toast";
import { useToast } from "./use-toast";
export function Toaster(): JSX.Element {
  const {
    toasts
  } = useToast();
  return <ToastProvider data-sentry-element="ToastProvider" data-sentry-component="Toaster" data-sentry-source-file="toaster.tsx">
      {toasts.map(function ({
      id,
      title,
      description,
      action,
      icon,
      ...props
    }) {
      return <Toast key={id} {...props}>
            <div className="grid gap-1">
              <div className="flex items-center gap-2">
                {icon != null && <Icon name={icon} />}
                {title != null && <ToastTitle>{title}</ToastTitle>}
                {description != null && <ToastDescription>{description}</ToastDescription>}
              </div>
              {action}
              <ToastClose />
            </div>
          </Toast>;
    })}
      <ToastViewport data-sentry-element="ToastViewport" data-sentry-source-file="toaster.tsx" />
    </ToastProvider>;
}