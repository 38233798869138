import Image from "next/image";
import { useCallback } from "react";
import { Button } from "../../atoms/button";
import { Input } from "../../atoms/input";
export interface IHeroSectionProps {
  title: string;
  description: string;
  imageUrl: string;
  onClickGetStarted: () => void;
}
export const HeroSection = ({
  title,
  description,
  imageUrl,
  onClickGetStarted
}: IHeroSectionProps): React.ReactNode => {
  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    onClickGetStarted();
  }, [onClickGetStarted]);
  return <section className="w-full py-10" data-sentry-component="HeroSection" data-sentry-source-file="HeroSection.tsx">
      <div className="container px-4 md:px-6">
        <div className="grid gap-12 lg:grid-cols-[1fr_500px] lg:gap-12 xl:grid-cols-[1fr_600px]">
          <div className="flex flex-col justify-center space-y-6">
            <div className="space-y-6">
              <div className="text-6xl tracking-tight sm:text-7xl md:text-8xl">{title}</div>
              <p className="max-w-[600px] text-xl text-gray-500 dark:text-gray-400 md:text-2xl">{description}</p>
            </div>
            <div className="w-full max-w-md space-y-3">
              <form className="flex items-center space-x-2">
                <Input className="h-12 w-[200px] flex-1" placeholder="Enter your email" type="email" data-sentry-element="Input" data-sentry-source-file="HeroSection.tsx" />
                <Button className="h-12 px-6" iconRight="arrow-right" size="lg" type="submit" onClick={handleClick} data-sentry-element="Button" data-sentry-source-file="HeroSection.tsx">
                  Get started
                </Button>
              </form>
              <p className="text-sm text-gray-500 dark:text-gray-400">Sign up to get started for free.</p>
            </div>
          </div>
          <Image alt="Hero" className="mx-auto aspect-square overflow-hidden rounded-xl object-cover object-center sm:w-full lg:order-last" height="550" src={imageUrl} width="550" data-sentry-element="Image" data-sentry-source-file="HeroSection.tsx" />
        </div>
      </div>
    </section>;
};