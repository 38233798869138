import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
const notificationDotVariants = cva("rounded-full", {
  variants: {
    targetVariant: {
      // Adding the button variants here to ensure an outline for dark looking buttons, and danger default on danger
      inverted: "bg-primary outline outline-paper",
      primary: "bg-primary outline outline-paper",
      secondary: "bg-primary",
      danger: "bg-red-600",
      ghost: "bg-primary",
      link: "bg-primary",
      muted: "bg-primary",
      outline: "bg-primary",
      accent: "bg-primary"
    },
    notificationKind: {
      default: "",
      forceDanger: "bg-red-500"
    }
  },
  defaultVariants: {
    notificationKind: "default",
    targetVariant: "muted"
  }
});
const NotificationDot: React.FC<{
  className?: string;
} & VariantProps<typeof notificationDotVariants>> = ({
  className,
  notificationKind,
  targetVariant
}) => <div className={cn(notificationDotVariants({
  notificationKind,
  targetVariant
}), className)} data-sentry-component="NotificationDot" data-sentry-source-file="notification-dot.tsx" />;
export default NotificationDot;
NotificationDot.displayName = "NotificationDot";
export { NotificationDot, notificationDotVariants };