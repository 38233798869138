import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import type { ClassValue } from "clsx";
import * as React from "react";
import { cn } from "../../lib/utils";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { Spinner } from "./spinner";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";
const buttonVariants = cva("relative inline-flex items-center justify-center gap-x-1.5 rounded-[3px] border border-transparent text-sm font-normal tracking-wider text-ink ring-offset-paper transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:pointer-events-none disabled:cursor-not-allowed", {
  variants: {
    variant: {
      inverted: "bg-ink text-paper hover:brightness-90 active:brightness-75",
      primary: "bg-primary text-primary-foreground hover:brightness-90 active:brightness-75",
      secondary: "bg-secondary hover:brightness-90 active:brightness-75",
      danger: "bg-red-100 text-red-600 hover:brightness-90 active:brightness-75 dark:bg-paper dark:text-red-600",
      ghost: "bg-transparent text-ink hover:bg-accent-background active:bg-accent-background active:brightness-90",
      muted: "bg-accent-background text-accent-foreground hover:brightness-90 active:brightness-95",
      link: "gap-x-1 bg-transparent !px-0 normal-case tracking-normal underline-offset-2 hover:underline active:text-ink",
      outline: "border border-ink bg-transparent text-ink hover:bg-muted-background active:bg-accent-background",
      accent: "bg-accent text-accent-foreground hover:brightness-75 active:brightness-90"
    },
    size: {
      xs: "h-6 px-1.5 text-xs",
      sm: "h-7 px-2.5",
      md: "h-8 px-3",
      lg: "h-9 px-3.5 text-base"
    }
  },
  defaultVariants: {
    variant: "primary",
    size: "sm"
  }
});
export interface IBaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  iconLeft?: IIconNames;
  iconRight?: IIconNames;
  isLoading?: boolean;
}
export type IButtonPropsWithoutChildren = {
  children?: never;
  title: string;
} | {
  children?: never;
  iconLeft: IIconNames;
};
export type IButtonPropsWithChildren = {
  children: React.ReactNode;
};
export type IButtonProps = IBaseButtonProps & (IButtonPropsWithoutChildren | IButtonPropsWithChildren);
function getSquareStyles(children: IBaseButtonProps["children"], iconLeft: IBaseButtonProps["iconLeft"], iconRight: IBaseButtonProps["iconRight"], size: IBaseButtonProps["size"]): ClassValue {
  if (children == null && (iconLeft != null || iconRight != null)) {
    switch (size) {
      case "xs":
        return "w-6 px-1";
      case "sm":
        return "w-7 px-1";
      case "md":
        return "w-8 px-1";
      default:
        return "w-10 px-1";
    }
  } else {
    return "";
  }
}
const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  children,
  iconLeft,
  iconRight,
  isLoading,
  ...props
}, ref) => {
  const ButtonComponent = asChild ? Slot : "button";
  const iconClass = cn(size === "xs" ? "size-3" : "size-4", "shrink-0");
  const squareStyles = getSquareStyles(children, iconLeft, iconRight, size);
  const content = <>
        {(iconLeft != null || children != null) && <>
            {iconLeft != null && <Icon className={cn(iconClass, isLoading === true && "opacity-0")} name={iconLeft} />}
            {children != null && <span className={cn("truncate", isLoading === true && "opacity-0")}>{children}</span>}
          </>}
        {iconRight != null && <Icon className={cn(iconClass, isLoading === true && "opacity-0")} name={iconRight} />}
      </>;
  const buttonComponent = <ButtonComponent ref={ref} className={cn(buttonVariants({
    variant,
    size,
    className
  }), squareStyles)} disabled={props.disabled === true || isLoading} {...props}>
        {content}
        {isLoading === true && <div className="absolute inset-0 flex items-center justify-center">
            <Spinner className={iconClass} />
          </div>}
      </ButtonComponent>;
  const title = props.title;
  if (title == null) {
    return buttonComponent;
  }
  return <TooltipProvider delayDuration={300}>
        <Tooltip>
          <TooltipTrigger asChild>{buttonComponent}</TooltipTrigger>
          <TooltipContent>{title}</TooltipContent>
        </Tooltip>
      </TooltipProvider>;
});
Button.displayName = "Button";
export { Button, buttonVariants };