import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
const EntityStateIndicatorBackgroundVariants = cva("align-middle [&:has([role=checkbox])]:pr-0", {
  variants: {
    backgroundColor: {
      lilac: "bg-pastel-lilac-background group-hover:bg-pastel-lilac-background/80 group-data-[state=selected]:bg-pastel-lilac-background/80",
      purple: "bg-pastel-purple-background group-hover:bg-pastel-purple-background/80 group-data-[state=selected]:bg-pastel-purple-background/80",
      pink: "bg-pastel-pink-background group-hover:bg-pastel-pink-background/80 group-data-[state=selected]:bg-pastel-pink-background/80",
      red: "bg-pastel-red-background group-hover:bg-pastel-red-background/80 group-data-[state=selected]:bg-pastel-red-background/80",
      orange: "bg-pastel-orange-background group-hover:bg-pastel-orange-background/80 group-data-[state=selected]:bg-pastel-orange-background/80",
      brown: "bg-pastel-brown-background group-hover:bg-pastel-brown-background/80 group-data-[state=selected]:bg-pastel-brown-background/80",
      yellow: "bg-pastel-yellow-background group-hover:bg-pastel-yellow-background/80 group-data-[state=selected]:bg-pastel-yellow-background/80",
      lime: "bg-pastel-lime-background group-hover:bg-pastel-lime-background/80 group-data-[state=selected]:bg-pastel-lime-background/80",
      sage: "bg-pastel-sage-background group-hover:bg-pastel-sage-background/80 group-data-[state=selected]:bg-pastel-sage-background/80",
      green: "bg-pastel-green-background group-hover:bg-pastel-green-background/80 group-data-[state=selected]:bg-pastel-green-background/80",
      emerald: "bg-pastel-emerald-background group-hover:bg-pastel-emerald-background/80 group-data-[state=selected]:bg-pastel-emerald-background/80",
      teal: "bg-pastel-teal-background group-hover:bg-pastel-teal-background/80 group-data-[state=selected]:bg-pastel-teal-background/80",
      blue: "bg-pastel-blue-background group-hover:bg-pastel-blue-background/80 group-data-[state=selected]:bg-pastel-blue-background/80",
      neutral: "bg-pastel-neutral-background group-hover:bg-pastel-neutral-background/80 group-data-[state=selected]:bg-pastel-neutral-background/80",
      transparent: "bg-paper group-hover:bg-muted-background group-data-[state=selected]:bg-secondary"
    }
  },
  defaultVariants: {
    backgroundColor: "transparent"
  }
});
export type IEntityStateIndicatorBackgroundVariants = VariantProps<typeof EntityStateIndicatorBackgroundVariants>;
export const EntityStateIndicatorBackground = React.forwardRef<HTMLDivElement, React.TdHTMLAttributes<HTMLDivElement> & VariantProps<typeof EntityStateIndicatorBackgroundVariants>>(({
  className,
  backgroundColor,
  ...props
}, ref) => {
  return <div ref={ref} className={cn(EntityStateIndicatorBackgroundVariants({
    backgroundColor
  }), className)} {...props} />;
});
EntityStateIndicatorBackground.displayName = "EntityStateIndicatorBackground";