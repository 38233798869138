import * as React from "react";
import { cn } from "../../lib/utils";
import { Badge } from "./badge";
export const ByComputedAutoFilled: React.FC<{
  className?: string;
}> = ({
  className
}) => <div className={cn("flex items-center space-x-1", className)} data-sentry-component="ByComputedAutoFilled" data-sentry-source-file="by-computed-auto-filled.tsx">
    <Badge className="bg-muted-background hover:bg-muted-background" size="sm" data-sentry-element="Badge" data-sentry-source-file="by-computed-auto-filled.tsx">
      Autofilled
    </Badge>
  </div>;